import React from "react"
import ButtonGroup from "../../utils/button/group"
import Flex from "../../utils/flex"
import ctl from "@netlify/classnames-template-literals"
import HarryPath from "../../animations/harry-path"
import HarryPathMobile from "../../animations/harry-path-mobile"

const ListenToHarry = () => {
  return (
    <Flex
      firstItem={
        <>
          <div className={`md-max:hidden ${imageStyle}`}>
            <HarryPath/>
          </div>
          <div className="md:hidden mt-[60px]">
            <HarryPathMobile/>
          </div>
        </>
      }
      secondItem={
        <>
          <h2 className="text-[32px] md:text-[40px] font-extrabold md:min-w-[420px]">
            Listen to Harry,
            <br />
            receive trade signals &
            <br />
            take profitable action.
          </h2>
          <p className="md:hidden">
            Find out what financial instruments are, <br /> understand the lingo of the pros, Use
            technical <br /> tools, beat the markets and make profits.
          </p>
          <p className="md-max:hidden">
            Find out what financial instruments are,
            <br />
            understand the lingo of the pros, Use technical
            <br />
            tools, beat the markets and make profits.
          </p>

          <ButtonGroup fullWidthOnMobile buttons={buttons} />
        </>
      }
      className={flexStyle}
      firstItemClassName="relative md:left-[-66px]"
      secondItemClassName="md-max:mt-[60px] md-max:px-10"
    />
  )
}

export default ListenToHarry

const imageStyle = ctl(`md:max-w-[700px] md:mt-0 mt-12 md-max:hidden`)
const flexStyle = ctl(`md-max:pt-0 mb-[25px] md:mb-[100px] md:pr-[3%] 2xl:pr-[13%]`)

const buttons = [
  {
    label: "Receive Signals",
    url: "http://t.me/Egmarketsbot",
    className: "md-max:flex md-max:items-center md-max:text-sm md-max:justify-center md-max:h-[50px]"
  },
  {
    label: "Try Demo Trading",
    url: "https://mt4.egmarkets.trade/",
    className: "md-max:flex md-max:items-center md-max:text-sm md-max:justify-center md-max:h-[50px]",
    isExternal: true
  }
]
