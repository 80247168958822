import React from "react"
import ButtonGroup from "../../utils/button/group"
import Container from "../../utils/container"
import Flex from "../../utils/flex"
import ctl from "@netlify/classnames-template-literals"
import "./index.css"
import TradeWithHarry from "../../animations/trade-with-harry"
import TradeWithHarryMobile from "../../animations/trade-with-harry-mobile"

const TradeWithHarryIntro = () => {
  return (
    <Container
      size="large"
      className='intro'
    >
      <Flex
        alignTop
        firstItemClassName='lg:!min-w-[400px] md:pt-[30px]'
        firstItem={
          <>
            <h1 className='font-extrabold md:text-[40px] md:leading-[1.25]'>
              Learn to trade in<br className='!block'/>
              no time, get signals,<br className='!block' />
              become a pro.
            </h1>
            <p>
              Harry understands and provides immediate
              <br className='!block'/>
              {' '}solutions to all your trading needs, all in one
              <br className='!block'/>
              {' '}place, without any of the stress!
            </p>

            <ButtonGroup fullWidthOnMobile buttons={buttons} />
          </>
        }
        secondItem={
          <>
            <div className={`md-max:hidden ${imageStyle}`}>
              <TradeWithHarry />
            </div>
            <div className='md:hidden mx-auto max-w-[330px]'>
              <TradeWithHarryMobile/>
            </div>
          </>
        }
        className='md-max:pt-10'
      />
    </Container>
  )
}

const imageStyle = ctl(`mx-auto md:max-w-[500px]`)

const buttons = [
  {
    label: "Learn with Harry",
    url: "http://t.me/Egmarketsbot",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:text-sm md-max:justify-center md-max:h-[50px]',
  },
  {
    label: "Try Demo Trading",
    url: "https://mt4.egmarkets.trade/",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:text-sm md-max:justify-center md-max:h-[50px]',
  },
]

export default TradeWithHarryIntro
