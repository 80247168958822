import React from "react"
import Layout from "../components/layout"
import TradeWithHarryIntro from "../components/pages/trade-with-harry/intro"
import ListenToHarry from "../components/pages/trade-with-harry/listen-to-harry"
import TradingSteps from "../components/utils/trading/steps"
import ReceiveTradingSupport from "../components/utils/trading/support"
import TradingTargets from "../components/utils/trading/targets"

const TradeWithHarryPage = () => {
  return (
    <Layout title="Trade with Harry" description='With Harry, you get financial trading tips, signals, and learning on the go.'>
      <TradeWithHarryIntro />
      <ListenToHarry />
      <TradingTargets className='md-max:pt-[40px]' />
      <ReceiveTradingSupport className='md-max:!mt-[40px]'/>
      <div className='md-max:pt-[60px]'>
        <TradingSteps />
      </div>
    </Layout>
  )
}

export default TradeWithHarryPage
